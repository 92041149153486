import React from "react"

import "../css/pages/peinture-platerie.scss"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import {Link} from "gatsby";
import Contact from "../containers/index/contact";

const PeinturePlaterie = () => (
  <Layout>
    <SEO title="Peinture & Platerie à Laon dans l'Aisne (02)"
         description={"L'entreprise Athies Batiment basée à Laon intervient pour les travaux de peinture dans l'Aisne et dans la Marne. Faites appel à nos artisans peintres et plaquistes qualifiés."}/>
    <div className={"page peinture-platerie"}>
        <div className={"header"}>
            <h1 className={"title"}>Artisan peintre et plaquiste à Laon</h1>
        </div>

        <Section>
            <h2>Pourquoi faire appel à nos artisans ?</h2>
            <p>
                Vous souhaitez donner un coup de neuf à votre maison ? Vous souhaitez donner du <strong>charme et du style</strong> à votre habitation neuve ? Nos artisans <strong>peintres et plaquistes à Laon</strong> réaliseront vos travaux selon vos besoins et votre budget. Il est important de choisir <strong>des artisans compétents et qualifiés</strong> pour réaliser ces travaux.
            </p>

            <p>
                Nous réalisons différentes tâches :
                <ul>
                    <li>Disposition des éléments de <strong>cloisons</strong> (plaques de plâtre).</li>
                    <li>Réalisation des <strong>finitions</strong> : enduits et joins.</li>
                    <li>Intgration des éléments de menuiserie intérieur (vitrages, portes, etc.).</li>
                    <li>Montage de <strong>faux-plafonds</strong>.</li>
                    <li>Et bien d'autres tâches, contactez-nous pour en savoir plus.</li>
                </ul>
            </p>

            <p>
                Nos artisans peintres et plaquistes, <strong>qualifiés et expérimentés</strong>, réaliseront vos peintures et vos travaux d'intérieurs selon vos besoins. Nous intervenons principalement dans <strong>l’Aisne (02) et dans la Marne (51)</strong>, dans le secteur proche de Laon, mais nous pouvons aussi nous déplacer dans d'autres secteurs. Pour cela, contactez-nous : <Link to={"/#contact"}>cliquez-ici</Link>.
            </p>
        </Section>

        <Contact/>
    </div>
  </Layout>
)

export default PeinturePlaterie
